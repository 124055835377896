/* eslint-disable max-len */
import React from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Select,
  Card,
  Space,
  notification,
} from 'antd';
import {
  ArrowRightOutlined,
  LockFilled,
} from '@ant-design/icons';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { RecaptchaVerifier } from 'firebase/auth';

import i18n from '../../i18n';
import getAgentInstance from '../../helpers/superagent';
import { authentication } from '../../firebase/firebase';

import './login.css';

const { Option } = Select;
const superagent = getAgentInstance();

@inject('tokenStore', 'sectionsStore', 'userStore')
@observer
class Login extends React.Component {
  constructor() {
    super();
    this.recaptchaVerifier = null;
    this.initialState = () => ({
      userCompanyName: '',
      loading: false,
      isUserValid: false,
      formValues: undefined,
    });
    this.state = this.initialState();

    this.onSubmit = () => {
      this.setState({ loading: true });

      this.recaptchaVerifier.verify().catch((error) => {
        console.error('Recaptcha verification failed:', error);
        this.setState({ loading: false });
      });
    };

    this.sendAuthFirstRequest = () => {
      const formValues = this.formRef.current.getFieldValue();
      const phoneNo = `964${formValues.phone_no.replace(/\s/g, '')}`;

      const firstData = {
        phone_no: phoneNo,
        passcode: formValues.passcode,
      };

      superagent
        .post(`${process.env.REACT_APP_API_LINK}/User/first`)
        .send(firstData)
        .end((err) => {
          if (!err) {
            this.setState(
              {
                formValues: {
                  phone_no: phoneNo,
                  passcode: formValues.passcode,
                },
              },
              () => {
                this.setState({ isUserValid: true, loading: false });
              },
            );
          } else this.setState({ loading: false });
        });
    };

    this.verifyOtp = () => {
      const otp = this.formRef.current.getFieldValue(['code']);
      this.setState({ otpLoadingBtn: true });
      if (otp.length === 6) {
        const { formValues } = this.state; // Use the formValues from the state
        const otpData = {
          phone_no: formValues.phone_no,
          passcode: formValues.passcode,
          otp: parseInt(otp, 10), // Ensure otp is sent as a number
        };

        superagent
          .post(`${process.env.REACT_APP_API_LINK}/User/second`)
          .send(otpData)
          .then((res) => {
            this.onVerified(res);
          })
          .catch((err) => {
            // Handle error
            notification.error({
              message: 'Verification Failed!',
              description: `Something went wrong, ${err.message}`,
              placement: 'bottomRight',
            });
            this.setState({ otpLoadingBtn: false });
          });
      } else {
        // Handle invalid OTP length
        notification.error({
          message: 'Invalid Input!',
          description: 'Input length should contain a 6 digit code!',
          placement: 'bottomRight',
        });
        this.setState({ otpLoadingBtn: false });
      }
    };

    this.onVerified = (res) => {
      const { body } = res;
      const { tokenStore, userStore } = this.props;

      if (body) {
        userStore.value = body.user;
        tokenStore.value = body.token;
        window.location.reload();
        message.success({ content: 'Successfully logged in' });
      } else {
        message.error({ content: body.msg });
      }

      this.setState({ loading: false });
    };

    this.onGoBack = () => {
      window.location.reload();
    };

    this.handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        this.onSubmit();
      }
    };

    this.handleVerifyOtpKeyDown = (event) => {
      if (event.key === 'Enter') {
        this.verifyOtp();
      }
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (i18n.language === 'en-US') {
      i18n.changeLanguage('en');
      window.location.reload();
    }

    this.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          this.sendAuthFirstRequest();
        },
      },
      authentication,
    );
    this.recaptchaVerifier.render();
  }

  render() {
    const { loading, isUserValid, otpLoadingBtn } = this.state;

    // const menu = (
    //   <Menu style={{ minWidth: '120px' }}>
    //     <Menu.Item
    //       onClick={() => i18n.changeLanguage('en')}
    //       icon={<RightOutlined style={{ color: '#9A9A9A' }} />}
    //     >
    //       {i18n.t('English')}
    //     </Menu.Item>
    //     <Menu.Item
    //       onClick={() => i18n.changeLanguage('sr')}
    //       icon={<RightOutlined style={{ color: '#9A9A9A' }} />}
    //     >
    //       {i18n.t('Kurdish-Sorani')}
    //     </Menu.Item>
    //     <Menu.Item
    //       onClick={() => i18n.changeLanguage('ba')}
    //       icon={<RightOutlined style={{ color: '#9A9A9A' }} />}
    //     >
    //       {i18n.t('Kurdish-Badini')}
    //     </Menu.Item>
    //     <Menu.Item
    //       onClick={() => i18n.changeLanguage('ar')}
    //       icon={<RightOutlined style={{ color: '#9A9A9A' }} />}
    //     >
    //       {i18n.t('Arabic')}
    //     </Menu.Item>
    //   </Menu>
    // );

    return (
      <>
        <Card className="login-card font-calss" style={{ direction: 'rtl' }}>
          <Form onFinish={this.onFinish} layout="vertical" ref={this.formRef}>
            <Row justify="center" gutter={[0, 10]}>
              <Col>
                <p className="login-card-title font-calss">
                  <img src="/edition-logo.png" style={{ height: '100px' }} alt="logo" />
                </p>
              </Col>
            </Row>
            <Row justify="center" gutter={[0, 64]}>
              <Col style={{ textAlign: 'center' }}>
                <p className="login-card-subtitle font-calss">
                  لإدارة أعمالك العقارية
                </p>
                <p className="font-calss">
                  بۆ بەڕێوەبردنی کاروباری خانووبەرەت
                </p>
              </Col>
            </Row>
            {!isUserValid ? (
              <>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="phone_no"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      className="Input_with_addBefores"
                    >
                      <Input
                        className="login_phone_input"
                        placeholder="ژمارەی مۆبایل / رقم الهاتف"
                        required
                        addonAfter={(
                          <Select defaultValue="964">
                            <Option value="964">+964</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Form.Item
                      name="passcode"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password
                        className="login_Input"
                        onKeyDown={this.handleKeyDown}
                        placeholder="ژمارەی نهێنی / قم بإدخال رمز التحقق"
                        dir="ltr"
                        required
                        prefix={(
                          <LockFilled
                            style={{ fontSize: '20px', marginRight: '10px' }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        block
                        loading={loading}
                        onClick={this.onSubmit}
                        className="font-calss login_btn"
                        style={{ height: '60px', borderRadius: '10px' }}
                      >
                        <p
                          className="login-card-subtitle font-calss"
                          style={{ fontSize: 12 }}
                        >
                          {i18n.t(
                            'دڵنیابوونەوە لە چوونەژوورەوە / تأكيد تسجيل الدخول',
                          )}
                        </p>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[0, 32]}>
                  <Col span={24}>
                    <Form.Item
                      name="code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        required
                        onKeyDown={this.handleVerifyOtpKeyDown}
                        className="login_Input second_login_btn"
                        placeholder="ژمارەی دڵنیابوونەوە / رقم التحقق"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        block
                        loading={otpLoadingBtn}
                        onClick={this.verifyOtp}
                        className="font-calss login_btn"
                        style={{ height: '60px', borderRadius: '10px' }}
                      >
                        <p
                          className="login-card-subtitle font-calss"
                          style={{ fontSize: 12 }}
                        >
                          {i18n.t('چوونەژورەوە/ تسجيل الدخول')}
                        </p>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col span={isUserValid ? 6 : 0}>
                <Form.Item>
                  <Button
                    size="large"
                    type="text"
                    className="font-calss"
                    onClick={() => this.onGoBack()}
                    style={{
                      height: '20px',
                      borderRadius: '10px',
                      fontFamily: 'kurdish_regular',
                    }}
                  >
                    <Space>
                      <ArrowRightOutlined />
                      <p
                        className="font-calss"
                        style={{ fontSize: 12, marginRight: 10 }}
                      >
                        گەڕانەوە / الرجوع
                      </p>
                    </Space>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={[0, 24]} justify="center">
              <Col className="center">
                <Dropdown
                  overlay={menu}
                  trigger="click"
                  placement="bottomCenter"
                >
                  <span className="ant-dropdown-link pointer">
                    {i18n.t('Languages')}
                    {' '}
                    <DownOutlined />
                  </span>
                </Dropdown>
              </Col>
            </Row> */}
            <Row justify="center">
              <Col>
                <p
                  className="login-card-subtitle font-calss"
                  style={{ fontSize: 12 }}
                >
                  بەرهەمی کۆمپانیای
                  {' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://lucid-source.com"
                    style={{ fontFamily: 'Cuprumsemi' }}
                  >
                    <span style={{ fontSize: 16 }}>
                      {i18n.t('Lucid Source')}
                    </span>
                  </a>
                </p>
              </Col>
            </Row>
          </Form>
        </Card>
        <div id="recaptcha-container" />
      </>
    );
  }
}

export default withTranslation()(Login);
