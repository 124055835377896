/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Dropdown,
  Modal,
  Col,
  Row,
  Badge,
  notification,
} from 'antd';
import {
  TagOutlined,
  TeamOutlined,
  UserOutlined,
  DownOutlined,
  AuditOutlined,
  SnippetsOutlined,
  PoweroffOutlined,
  MenuFoldOutlined,
  FileExcelOutlined,
  ContainerOutlined,
  ApartmentOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  RedEnvelopeOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
  UsergroupDeleteOutlined,
  PieChartOutlined,
  HomeFilled,
  BellOutlined,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@ant-design/icons';
import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import i18n from '../../i18n';
import routes from './dashboardRoutes';
import Loading from '../basic/Loading';
import getAgentInstance from '../../helpers/superagent';
// import largeLogo from '../. ./assets/images/icons/lucid-bg-logo.svg';

import './dashboard.css';

const superagent = getAgentInstance();
const {
  Header, Content, Footer, Sider,
} = Layout;
const rootSubmenuKeys = ['/', '2', '6', '4', '7'];

@inject('userStore', 'tokenStore', 'sectionsStore', 'notification')
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      loading: true,
      collapsed: false,
      defualtMenu: 'visitlist',
      UIFlags: {},
      brandingLogo: '',
      brandingColor: '',
      openKeys: [],
      currencies: [],
      visibleNotification: false,
      hasLoginNoticeFlashed: false,
      notificationRecords: 0,
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const { userStore, tokenStore, sectionsStore } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
      ]).then(() => {});
    };

    this.fetchNotification = () => {
      this.setState({ loading: true });
      superagent.get('/partialPaymentNotificationsCount').end((err, res) => {
        if (!err) {
          const { body } = res;
          this.setState({
            loading: false,
            notificationRecords: body?.records,
          });
        }
      });
    };

    this.warning = () => {
      const scope = this;
      Modal.confirm({
        centered: true,
        title: i18n.t('Logout Of The System?'),
        content: '',
        okText: i18n.t('Yes'),
        cancelText: i18n.t('No'),
        onOk() {
          scope.logout();
        },
        onCancel() {},
      });
    };
  }

  componentDidMount() {
    const scope = this;
    if (!this.state.hasLoginNoticeFlashed) {
      superagent.get('/User/me').end((err, res) => {
        const { userStore } = this.props;
        if (res?.body?.role) {
          userStore.value = res.body;
          this.setState({ access: JSON.parse(res?.body?.role?.privileges) });
        }

        if (err && err.status === 400) {
          userStore.value = '';
          this.setState({ hasLoginNoticeFlashed: true }, () => {
            notification.error({
              message: 'Unauthorized!',
              description: 'Multiple login devices detected! Logging out...',
              placement: 'bottomRight',
            });

            scope.logout();
          });
        }
        this.setState({ loading: false });
      });
    }

    this.fetchNotification();
  }

  render() {
    const { location, userStore, history } = this.props;

    const {
      collapsed,
      loading,
      access,
      brandingColor,
      openKeys,
      notificationRecords,
    } = this.state;
    const { pathname } = location;
    const { value } = userStore;
    let sectionName;
    if (pathname === '/') {
      sectionName = i18n.t('Statistics');
    } else if (pathname === '/unit-list') {
      sectionName = i18n.t('Unit');
    } else if (pathname === '/reports') {
      sectionName = i18n.t('Reports');
    } else if (pathname === '/unit-type') {
      sectionName = i18n.t('Unit Type');
    } else if (pathname === '/project') {
      sectionName = i18n.t('Project');
    } else if (pathname === '/contact-list') {
      sectionName = i18n.t('Contacts');
    } else if (pathname === '/contact-person-type') {
      sectionName = i18n.t('Contact Person Type');
    } else if (pathname === '/invoice') {
      sectionName = i18n.t('Invoice');
    } else if (pathname === '/receipt') {
      sectionName = i18n.t('Receipt');
    } else if (pathname === '/payment-notification') {
      sectionName = i18n.t('Payment Notification');
    } else if (pathname === '/offer') {
      sectionName = i18n.t('Offer');
    } else if (pathname === '/account-statement') {
      sectionName = i18n.t('Account Statement');
    } else if (pathname === '/investor') {
      sectionName = i18n.t('Investor Account');
    } else if (pathname === '/investor-payment') {
      sectionName = i18n.t('Investor Payment');
    } else if (pathname === '/investor-transaction') {
      sectionName = i18n.t('Investor Transaction');
    } else if (pathname === '/sms') {
      sectionName = i18n.t('SMS');
    } else if (pathname === '/sale-contract') {
      sectionName = i18n.t('Sale Contracts');
    } else if (pathname === '/sales-agent') {
      sectionName = i18n.t('Sales Agent');
    } else if (pathname === '/archive-contracts') {
      sectionName = i18n.t('Archives');
    } else if (pathname === '/user-profile') {
      sectionName = i18n.t('User Profile');
    } else if (pathname === '/dashboard') {
      sectionName = i18n.t('Dashboard');
    }
    const menu = (
      <Menu style={{ top: '10px' }}>
        {access?.ViewUser && (
          <Menu.Item
            onClick={() => history.push('/user-profile')}
            icon={(
              <TeamOutlined
                style={{
                  color: brandingColor !== '' ? brandingColor : '#65DFC2',
                }}
              />
            )}
          >
            {i18n.t('User Profile')}
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item
          onClick={this.warning}
          icon={(
            <PoweroffOutlined
              style={{
                color: brandingColor !== '' ? brandingColor : '#65DFC2',
              }}
            />
          )}
        >
          {i18n.t('Logout')}
        </Menu.Item>
      </Menu>
    );

    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openKeys: keys });
      } else {
        this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
      }
    };

    return (
      <>
        <style>
          {`

          .sider_toggler_btn {
            position: static !important;
          top: 0 !important;
          left: 0px !important;
          z-index: 2;
          }

          .sider_menu_links.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item:hover {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item-selected::before {
              content: '';
              width: 4px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: ${brandingColor};
          }
        `}
        </style>
        <div
          style={{
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: !loading ? 'none' : 'flex',
          }}
        >
          <Loading visible={loading} tip={i18n.t('Loading User Data...')} />
        </div>
        <Layout
          style={{
            minHeight: '100vh',
            display: loading ? 'none' : undefined,
          }}
        >
          <Sider
            theme="light"
            className="layout_sider"
            width={240}
            collapsed={collapsed}
            style={{
              height: '100vh',
              left: i18n.language === 'en' ? 0 : null,
              right: i18n.language !== 'en' ? 0 : null,
              position: 'fixed',
              overflow: 'auto',
            }}
          >
            <div className={collapsed ? 'logo_small' : 'logo'}>
              {collapsed ? (
                <img
                  alt="edition-logo"
                  style={{ width: 65 }}
                  src="/edition-logo.png"
                />
              ) : (
                <img
                  alt="edition-logo"
                  style={{ width: 185 }}
                  src="/edition-logo.png"
                />
              )}
            </div>
            <Menu
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              className="sider_menu_links"
              selectedKeys={[location.pathname]}
            >
              <Menu.Item
                key="/"
                onClick={() => this.setState({ openKeys: [] })}
              >
                <LineChartOutlined />
                <span>{i18n.t('Dashboard')}</span>
                <Link to="/" />
              </Menu.Item>

              {(access?.ViewProject
                || access?.ViewUnit
                || access?.ViewOffer) && (
                <Menu.SubMenu
                  key={2}
                  title={i18n.t('Unit Info')}
                  icon={<HomeFilled />}
                >
                  {access?.ViewUnit && (
                    <Menu.Item key="/unit-list">
                      <UnorderedListOutlined />
                      <span>{i18n.t('Unit Definition')}</span>
                      <Link to="/unit-list" />
                    </Menu.Item>
                  )}
                  {access?.ViewUnit && (
                    <Menu.Item key="/unit-type">
                      <ApartmentOutlined />
                      <span>{i18n.t('Unit Type')}</span>
                      <Link to="/unit-type" />
                    </Menu.Item>
                  )}
                  {access?.ViewProject && (
                    <Menu.Item key="/floor">
                      <ContainerOutlined />
                      <span>{i18n.t('Floors')}</span>
                      <Link to="/floor" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access?.ViewContract || access?.['View Archive Contracts']) && (
                <Menu.SubMenu
                  key={6}
                  title={i18n.t('Contracts')}
                  icon={<SnippetsOutlined />}
                >
                  {access?.ViewContract && (
                    <Menu.Item key="/sale-contract">
                      <TagOutlined />
                      <span>{i18n.t('Sale')}</span>
                      <Link to="/sale-contract" />
                    </Menu.Item>
                  )}
                  {access?.ViewArchiveContracts && (
                    <Menu.Item key="/cancel-contracts">
                      <AuditOutlined />
                      <span>{i18n.t('Cancelled Contracts')}</span>
                      <Link to="/cancel-contracts" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access?.ViewInvoice
                || access?.ViewReceipt
                || access?.ViewExpense
                || access?.ViewAccountStatement) && (
                <Menu.SubMenu
                  key={4}
                  title={i18n.t('Accounting')}
                  icon={<ReconciliationOutlined />}
                >
                  {access?.ViewReceipt && (
                    <Menu.Item key="/receipt">
                      <RedEnvelopeOutlined />
                      <span>{i18n.t('Vouchers')}</span>
                      <Link to="/receipt" />
                    </Menu.Item>
                  )}

                  {access?.ViewReceipt && (
                    <Menu.Item key="/safe">
                      <RedEnvelopeOutlined />
                      <span>{i18n.t('Safe')}</span>
                      <Link to="/safe" />
                    </Menu.Item>
                  )}

                  {access?.ViewReceipt && (
                    <Menu.Item key="/receipt-draft">
                      <FileExcelOutlined />
                      <span>{i18n.t('Archive')}</span>
                      <Link to="/receipt-draft" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}
              <Menu.Item key="/payment-notification" onClick={() => {}}>
                <BellOutlined />
                <Badge
                  count={notificationRecords}
                  size="small"
                  style={{
                    zIndex: '99',
                    marginRight: '-10px',
                    padding: '3px',
                    height: '20px',
                    borderRadius: '100%',
                  }}
                >
                  <span>{i18n.t('Payment Notification')}</span>
                </Badge>
                <Link to="/payment-notification" />
              </Menu.Item>

              {access?.ViewContact && (
                <Menu.Item
                  key="/contact-list"
                  onClick={() => this.setState({ openKeys: [] })}
                >
                  <UsergroupDeleteOutlined />
                  <span>{i18n.t('Contacts')}</span>
                  <Link to="/contact-list" />
                </Menu.Item>
              )}

              {access?.ViewReports && (
                <Menu.Item
                  key="/reports"
                  onClick={() => this.setState({ openKeys: [] })}
                >
                  <PieChartOutlined />
                  <span>{i18n.t('Reports')}</span>
                  <Link to="/reports" />
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          {i18n.language === 'en' ? (
            <Layout
              className="site-layout"
              style={{
                transition: 'all 0.3s',
                marginLeft: collapsed === false ? 240 : 80,
                marginRight: 0,
              }}
            >
              <Header
                className="site-layout-background"
                style={{ padding: 0, display: 'inherit', position: 'relative' }}
              >
                <Col>
                  <div className="sider_toggler_btn">
                    <Button
                      type="link"
                      icon={
                        collapsed ? (
                          <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                        ) : (
                          <MenuFoldOutlined style={{ color: '#1f2532' }} />
                        )
                      }
                      onClick={this.toggleSidebar}
                      style={{
                        textAlign: 'start',
                        paddingLeft: 10,
                        paddingRight: undefined,
                      }}
                    />
                  </div>
                </Col>
                <div className="main_header_container">
                  <div className="section_title">{sectionName || ''}</div>
                  <div className="header_avatar">
                    <Row gutter={[10, 0]}>
                      <Col>
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ar');
                                  window.location.reload();
                                }}
                                key="4"
                                style={{ fontFamily: 'arabic_regular' }}
                              >
                                عربي
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('sr');
                                  window.location.reload();
                                }}
                                key="2"
                                style={{ fontFamily: 'kurdish_regular' }}
                              >
                                کوردی-سۆرانی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('en');
                                  window.location.reload();
                                }}
                                key="1"
                                style={{ fontFamily: 'CuprumRegular' }}
                              >
                                English
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {i18n.language === 'ar' ? 'العربية' : null}
                            {' '}
                            {i18n.language === 'sr' ? 'کوردی-سۆرانی' : null}
                            {i18n.language === 'ba' ? 'کوردی-بادینی' : null}
                            {i18n.language === 'en' ? 'English' : null}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col>
                        <Avatar shape="square" icon={<UserOutlined />} />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menu}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                            {' '}
                            <DownOutlined style={{ marginLeft: '5px' }} />
                          </span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Header>

              <Content>
                <div
                  className="site-layout-background"
                  style={{
                    padding: '8px 8px 0',
                    minHeight: 500,
                    backgroundColor: 'transparent',
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact={route.exact}
                          key={route.key}
                          path={`${route.path}`}
                        >
                          <route.component />
                        </Route>
                      ))}
                    </Switch>
                  </Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                <span>{i18n.t('Designed and Developed By')}</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://lucid-source.com"
                  title="Lucid Source"
                >
                  {i18n.t('Lucid Source')}
                </a>
              </Footer>
            </Layout>
          ) : (
            <Layout
              className="site-layout"
              style={{
                transition: 'all 0.3s',
                marginRight: collapsed === false ? 240 : 80,
                marginLeft: 0,
              }}
            >
              <Header
                className="site-layout-background"
                style={{ padding: 0, display: 'inherit', position: 'relative' }}
              >
                <Col
                  span={1}
                  style={{
                    textAlign: 'start',
                    paddingLeft: undefined,
                    paddingRight: 10,
                  }}
                >
                  <Button
                    type="link"
                    icon={
                      collapsed ? (
                        <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                      ) : (
                        <MenuFoldOutlined style={{ color: '#1f2532' }} />
                      )
                    }
                    onClick={this.toggleSidebar}
                  />
                </Col>
                <div className="main_header_container">
                  <div className="section_title">{sectionName || ''}</div>
                  <div className="header_avatar">
                    <Row gutter={[10, 0]}>
                      <Col>
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ar');
                                  window.location.reload();
                                }}
                                key="4"
                                style={{ fontFamily: 'arabic_regular' }}
                              >
                                عربي
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('sr');
                                  window.location.reload();
                                }}
                                key="2"
                                style={{ fontFamily: 'kurdish_regular' }}
                              >
                                کوردی-سۆرانی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('en');
                                  window.location.reload();
                                }}
                                key="1"
                                style={{ fontFamily: 'CuprumRegular' }}
                              >
                                English
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {i18n.language === 'ar' ? 'العربية' : null}
                            {' '}
                            {i18n.language === 'sr' ? 'کوردی-سۆرانی' : null}
                            {i18n.language === 'ba' ? 'کوردی-بادینی' : null}
                            {i18n.language === 'en' ? 'English' : null}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col>
                        <Avatar shape="square" icon={<UserOutlined />} />
                      </Col>
                      <Col style={{ marginLeft: '10px' }}>
                        <Dropdown
                          overlay={menu}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                            {' '}
                            <DownOutlined style={{ marginRight: '5px' }} />
                          </span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Header>
              <Content>
                <div
                  className="site-layout-background"
                  style={{
                    padding: '8px 8px 0',
                    minHeight: 500,
                    backgroundColor: 'transparent',
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact={route.exact}
                          key={route.key}
                          path={`${route.path}`}
                        >
                          <route.component />
                        </Route>
                      ))}
                    </Switch>
                  </Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                <span>{i18n.t('Designed and Developed By')}</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://lucid-source.com"
                  title="Lucid Source"
                >
                  {i18n.t('Lucid Source')}
                </a>
              </Footer>
            </Layout>
          )}
        </Layout>
      </>
    );
  }
}

export default withTranslation()(
  withRouter((props) => <Dashboard {...props} />),
);
